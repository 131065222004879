<div id="footer">
    <!-- <input class="form-control mb-5" type="file" class="upload" (change)="changeListener($event.target.files)"> -->
    <div class="row h-per-100">
        <div class="col">
            <img src="/assets/redLogo.png" alt="" class="logo centrado">
        </div>
        <div class="col">
            <h2 class="fz-18 bold">
                Ubicación y Contacto
            </h2>
            <hr class="b-1">
            <h3 class="fz-14 bold m-px-b-5">
                Direccion
            </h3>
            <h4 class="fz-14 m-px-b-10 p-px-l-5">
                Calle 90 # 11 - 44, oficina 506 Bogota-Colombia
            </h4>
            <h3 class="fz-14 bold m-px-b-5">
                Telefono
            </h3>
            <h4 class="fz-14 m-px-b-10 p-px-l-5">
                310 8163038/314 2152547
            </h4>
            <h3 class="fz-14 bold m-px-b-5">
                Correo
            </h3>
            <h4 class="fz-14 m-px-b-10 p-px-l-5">
                gerencia@olindabienesraices.com
            </h4>
        </div>
        <div class="col social">
            <h2 class="fz-18 bold">
                Otros Canales
            </h2>
            <hr class="b-1">
            <h4 class="fz-14 m-px-b-25 p-px-l-50 item">
                <a href="https://www.facebook.com/olindaclavijoinmuebles/">
                    olindaclavijoinmuebles
                </a>
                <img src="/assets/icons/face.svg" alt="" class=" centrado-v red">
            </h4>
            <h4 class="fz-14 m-px-b-25 p-px-l-50 item">
                <a href="https://www.instagram.com/olinda.clavijo/?hl=es">
                    olinda.clavijo
                </a>
                <img src="/assets/icons/ins.svg" alt="" class=" centrado-v red">
            </h4>
            <h4 class="fz-14 m-px-b-25 p-px-l-50 item">
                <a href="https://wa.me/573142152547?text=Hola estoy deseando encontrar un apartamento a la venta">
                    314 2152547
                </a>
                <img src="/assets/icons/wa.svg" alt="" class=" centrado-v red">
            </h4>
        </div>
    </div>
</div>