import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../services/scroll.service'
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  selected;
  constructor(scroll: ScrollService, router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selected = event.url;
      }
    })
  }

  ngOnInit(): void {
  }
}
