<nav id="navbar" class="navbar fixed-top navbar-expand-lg navbar-dark" [class.small]='this.selected!="/home"'>
    <a class="navbar-brand" [routerLink]="['/home']">
        <img class="logo-big" src="/assets/logo.png" alt="logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav position-absolute">
            <li class="nav-item" [ngClass]="{'active': selected =='/home'}">
                <a class="nav-link" [routerLink]="['/home']">Inicio <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item" [ngClass]="{'active': selected =='/list'}">
                <a class="nav-link" [routerLink]="['/list']">Inmuebles <span class="sr-only">(current)</span></a>
            </li>
        </ul>
    </div>
</nav>