import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service'
@Pipe({ name: 'partodd' })
export class EvenOddPipe implements PipeTransform {
  transform(value: any[], filter: string) {
    if (!value || (filter !== 'even' && filter !== 'odd')) {
      return value;
    }
    return value.filter((item, idx) => filter === 'even' ? idx % 2 === 1 : idx % 2 === 0);
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  selected;
  services;
  opinions;
  types;
  zones;
  posters;
  imgs;
  constructor(public dataS: DataService, private router: Router) {
    this.selected = 0;
    dataS.getCol('/opinions', [], {}).subscribe(data => {
      this.opinions = data
    })
    dataS.getCol('/services', [], {}).subscribe(data => {
      this.services = data;
    })
    dataS.getCol('/posters', [], {}).subscribe(data => {
      this.posters = data;
    })

  }

  ngOnInit(): void {
    this.dataS.getDoc('/general', 'homeImages').subscribe((data: any) => {
      this.imgs = data;
      document.getElementById('s2').style.backgroundImage = `url(${data.search})`;
      document.getElementById('s5').style.backgroundImage = `url(${data.search})`;
      let opinions: any = document.querySelector('#s6 #opinions')
      opinions.style.backgroundImage = `url(${data.clients})`;
      let opinionsS: any = document.querySelector('#s6 #opinions-s')
      opinionsS.style.backgroundImage = `url(${data.clients})`;
    })
  }


  search() {
    let options = ['Venta', 'Arriendo', 'Remate']
    let select = options[this.selected];
    let data = {
      mode: select,
      types: this.types,
      zones: this.zones,
      price: [0, 1000000000],
      m2: [20, 300],
      rooms: new Array(5).fill(false),
      status: new Array(5).fill(false),
      baths: new Array(5).fill(false),
      cars: new Array(5).fill(false),
    }
    this.router.navigate(['/list', data])
  }

}
