<div class="content" *ngIf="dataS.basicData">
    <div class="section" id="s1">
        <div class="text">
            <h1 class="fz-52 m-px-t-0 m-px-b-40 bold title">
                Construyamos tu proyecto ideal
            </h1>
            <h2 class="fz-20 m-px-t-0 m-px-b-35 desc">
                Encuentra en Olinda Bienes Raíces el inmueble que deseas adquirir o en el que deseas invertir </h2>
            <a class="btn btn-primary fz-20 p-px-10" target="_blank"
                href="https://wa.me/573142152547?text=Hola estoy deseando encontrar un apartamento a la venta">
                Habla con nosotros
            </a>
        </div>
        <img class="photo" [src]="imgs?imgs.h1:'/assets/placeholder.png'" alt="">
    </div>
    <div class="section" id="s2">
        <div class="data centrado-h">
            <div class="row no-gutters color-white">
                <div class="col">
                    <h2 class="text-center m-px-0 bold p-px-y-30 title">Nuestros principales servicios</h2>
                </div>
            </div>
            <div class="row no-gutters options">
                <div class="col fit" (click)="selected=0">
                    <svg viewBox="0 0 512 512" class="w-per-30 h-per-100 centrado-h"
                        [ngClass]="{'color-white':selected!=0, 'color-secondary':selected==0}">
                        <use [attr.xlink:href]="'/assets/home/venta.svg#main'"></use>
                    </svg>
                    <h2 class="text-center" [ngClass]="{'color-white':selected!=0, 'color-secondary':selected==0}">
                        Venta
                    </h2>
                </div>
                <div class="col fit" (click)="selected=1">
                    <svg viewBox="0 0 512 512" class="w-per-30 h-per-100 centrado-h"
                        [ngClass]="{'color-white':selected!=1, 'color-secondary':selected==1}">
                        <use [attr.xlink:href]="'/assets/home/arriendo.svg#main'"></use>
                    </svg>
                    <h2 class="text-center" [ngClass]="{'color-white':selected!=1, 'color-secondary':selected==1}">
                        Arriendo
                    </h2>
                </div>
                <div class="col fit" (click)="selected=2">
                    <svg viewBox="0 0 512 512" class="w-per-30 h-per-100 centrado-h"
                        [ngClass]="{'color-white':selected!=2, 'color-secondary':selected==2}">
                        <use [attr.xlink:href]="'/assets/home/remate.svg#main'"></use>
                    </svg>
                    <h2 class="text-center " [ngClass]="{'color-white':selected!=2, 'color-secondary':selected==2}">
                        Remate
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h2 class="text-center m-px-0 p-px-y-30 color-white bold title">
                        Filtra tu busqueda
                    </h2>
                </div>
            </div>
            <div class="row selects p-px-b-30">
                <div class="col-lg-5 col-12">
                    <ng-select class="capitalize" [items]="dataS.basicData.types" [(ngModel)]='types'
                        placeholder="Tipo de inmueble">
                    </ng-select>
                </div>

                <div class="col-lg-5 col-12">
                    <ng-select class="capitalize" [items]="dataS.basicData.zones" [(ngModel)]='zones'
                        placeholder="Zona del Inmueble">
                    </ng-select>
                </div>

                <div class="col-lg-2 col-12">
                    <button class=" centrado btn btn-primary p-px-x-30 p-px-y-5 fz-20 br-5" (click)="search()">
                        Buscar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div id="s3" *ngIf="posters && posters.length>0">
        <div id="offers" class="carousel slide carousel-fade" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#offers" [data-slide-to]="i" [ngClass]="{'active':i==0}"
                    *ngFor="let p of posters;let i = index;"></li>
                <li data-target="#offers" data-slide-to="1" class="active"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active':i==0}" *ngFor="let p of posters;let i = index;">
                    <a [href]="p.link" class="overlink"></a>
                    <img [src]="p.img" class="d-block h-100 centrado-h" alt="...">
                    <div class="carousel-caption d-none d-md-block">
                        <h5>{{p.title}}</h5>
                        <p>{{p.text}}.</p>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#offers" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#offers" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
    <div id="s4">
        <div class="row box no-gutters h-per-100">
            <div class="col-lg-4 col-12 service" *ngFor="let s of services">
                <div class="cont">
                    <div class="card">
                        <img [src]="s.photo?s.photo:'/assets/home/1.jpg'" alt="" class="background">
                        <div class="flip centrado">
                            <h3 class="title">
                                {{s.name}}
                            </h3>
                            <div class="desc">
                                {{s.text}}
                            </div>
                            <a class="btn btn-wa fz-16 w-per-60 centrado-h" target="_blank"
                                [href]="'https://wa.me/573142152547?text=Hola estoy interesado en '+s.name">
                                Comunicate ahora
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="s5">
        <img src="/assets/foto.png" alt="" class="profile">
        <div class="info">
            <h2 class="title centrado-h fz-32 w-per-100 text-center">
                Conoce A Olinda Clavijo
            </h2>
            <div class="text centrado fz-20 w-per-90">
                Olinda Bienes Raíces surge tras la necesidad de aportar un servicio profesional y acorde a la necesidad
                tanto de compradores, como de vendedores. En el año dosmil diez se da paso a la innovación, tomando el
                bienestar de las partes como el principal factor para Olinda Bienes Raíces; se presentaron oportunidades
                de aprendizaje que forjaron un conocimiento integral que, no solamente han sido de las sendas
                principales al éxito, sino que sentaron las bases para brindarle a nuestros clientes el mejor desenlace
                posible de sus proyectos
            </div>
        </div>
    </div>
    <div id="s6">
        <div id="opinions" class="carousel slide d-none d-lg-block" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#opinions" data-slide-to="0" [ngClass]="{'active':i==0}"
                    *ngFor="let data of opinions | partodd:'odd'; index as i"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active':i==0}"
                    *ngFor=" let opinion of opinions | partodd:'odd'; index as i">
                    <div class="row content centrado">
                        <div class="col-6">
                            <div class="opinion-cont centrado-h">
                                <img [src]="opinion.photo" alt="" class="photo centrado-v">
                                <div class="opinion">
                                    <h1 class="name bold">
                                        {{opinion.name}}
                                    </h1>
                                    <h2 class="text centrado">
                                        "{{opinion.text}}"
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-6" *ngIf="opinions[i*2+1]">
                            <div class="opinion-cont centrado-h">
                                <img [src]="opinions[i*2+1].photo" alt="" class="photo centrado-v">
                                <div class="opinion">
                                    <h1 class="name bold">
                                        {{opinions[i*2+1].name}}
                                    </h1>
                                    <h2 class="text centrado">
                                        "{{opinions[i*2+1].text}}"
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="title bold">
                Muestras de nuestra trayectoria
            </h2>
            <a class="carousel-control-prev" href="#opinions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#opinions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

        <div id="opinions-s" class="carousel slide d-lg-none" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#opinions-s" data-slide-to="0" [ngClass]="{'active':i==0}"
                    *ngFor="let data of opinions | partodd:'odd'; index as i"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active':i==0}"
                    *ngFor=" let opinion of opinions ; let i = index">
                    <div class="row content centrado">
                        <div class="col-12">
                            <div class="opinion-cont centrado-h">
                                <img [src]="opinion.photo" alt="" class="photo centrado-v">
                                <div class="opinion">
                                    <h1 class="name bold">
                                        {{opinion.name}}
                                    </h1>
                                    <h2 class="text centrado">
                                        "{{opinion.text}}"
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="title bold">
                Muestras de nuestra trayectoria
            </h2>
            <a class="carousel-control-prev" href="#opinions-s" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#opinions-s" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>


    </div>
    <app-footer></app-footer>
</div>