import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private dataS: DataService) { }

  ngOnInit(): void {
    this.dataS.getDoc('/general', 'homeImages').subscribe((data: any) => {
      document.getElementById('footer').style.backgroundImage = `url(${data.footer})`;
    })

  }
  lines = [];
  linesR = [];

  changeListener(files: FileList) {
    console.log(files);
    if (files && files.length > 0) {
      let file: File = files.item(0);
      //File reader method
      let reader: FileReader = new FileReader();
      reader.readAsText(file, 'ISO-8859-4');
      reader.onload = (e) => {
        let csv: any = reader.result;
        let allTextLines = [];
        allTextLines = csv.split(/\r|\n|\r/);

        //Table Headings
        let headers = allTextLines[0].split(';');
        let data = headers;
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        //Pusd headings to array variable
        this.lines = tarr;


        // Table Rows
        let tarrR = [];

        let arrl = allTextLines.length;
        let rows = [];
        let obj = {}
        for (let i = 1; i < arrl; i++) {
          let datas = allTextLines[i].split(';');
          obj = {
            id: parseInt(datas[0]),
            address: datas[1],
            zone: datas[2],
            type: datas[3],
            mode: datas[4].split('/'),
            m2: parseInt(datas[5]),
            m2T: parseInt(datas[6]),
            level: parseInt(datas[7]),
            room: parseInt(datas[8]),
            bath: parseInt(datas[9]),
            status: parseInt(datas[10]),
            rent: parseInt(datas[11]),
            admin: parseInt(datas[12]),
            price: parseInt(datas[13]),
            description: datas[14],
            video: datas[15],
            car: parseInt(datas[16]),
            carC: parseInt(datas[17]),
            year: parseInt(datas[18]),
            use: datas[19],
            register: datas[20],
            state: true,
            imgs: []
          };
          rows.push(obj);
        }
        console.log(rows)

        this.linesR = rows;
        this.dataS.load(rows)
      }
    }
  }

}
